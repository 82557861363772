import "./App.css";
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Pages/Layout";
import Plugins from "./Pages/Plugins";
import NotFound from "./Pages/NotFound";
import Feedback from "./Pages/Feedback";
import HelpAndDocs from "./Pages/HelpAndDocs";
import PluginDetails from "./Pages/PluginDetails";
import MartvillLoader from "./Components/MartvillLoader";

// ** Lazy Load Pages
const Home = lazy(() => import("./Pages/Home"));

function App() {
	return (
		<BrowserRouter>
			<Suspense 
				fallback={
					<div className="h-screen flex items-center justify-center">
						<MartvillLoader />
					</div>
				}
			>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Home />} />
						<Route path="plugins" element={<Plugins />} />
						<Route path="plugins/:pluginId/:pluginName" element={<PluginDetails />} />
						<Route path="feedback" element={<Feedback />} />
						<Route path="help-and-docs" element={<HelpAndDocs />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
