const Button = ({ selected, onClick, children }) => {
	return (
		<button
			onClick={onClick}
			className={`flex items-center justify-center py-1.5 px-5 text-sm text-center font-semibold text-light_black border border-gray rounded-sm ${
				selected ? "bg-light_black text-white" : "bg-white"
			} hover:bg-light_black hover:text-white transition-all`}
		>
			{children}
		</button>
	);
};

export default Button;
