import React, { useEffect, useState } from "react";
import facebook from "../../assets/icon/facebook.svg";
import linkedIn from "../../assets/icon/linkedin.svg";
import Availability from "./Availability";
import CopyRightText from "./CopyRightText";
import FleetCart from "./FleetCart";
import GetInTouch from "./GetInTouch";
import Products from "./Products";
import SocialMediaIcon from "./SocialMediaIcon";
import ScrollToTopFAB from "../ScrollToTopFAB";
import useFetchAddons from "../../hooks/useFetchAddons";

const socialLink = [
  {
    id: 1,
    socialMediaLogo: facebook,
    url: "https://www.facebook.com/techvill",
    color: "facebook",
  },
  {
    id: 2,
    socialMediaLogo: linkedIn,
    url: "https://www.linkedin.com/company/techvill/",
    color: "linkedIn",
  },
];

function Footer() {
  const { data, isLoading } = useFetchAddons();
  const [martVillPrice, setMartVillPrice] = useState(0);

  // Fetch MartVill price
  useEffect(() => {
    if (data?.length) {
      const martvill = data?.find((addon) => addon?.id === 43288879);
      setMartVillPrice(martvill?.price_cents / 100);
    }
  }, [data]);

  return (
    <div className="bg-light_black !text-white font-medium">
      <div className="container mx-auto px-5 3xl:px-0 flex flex-wrap lg:flex-nowrap flex-row  justify-between  pt-[36px] lg:pt-[59px] pb-9 lg:pb-[52px] gap-y-[50px] lg:space-y-0 max-w-7xl">
        <Availability />
        <Products />
        <GetInTouch />
      </div>
      {/* Copyright Section */}
      <div className="bg-[#202020] border-t border-[#777777] ">
        <div className="pt-6 pb-[26px] 2xl:py-[25px] flex flex-col-reverse lg:flex-row justify-between container mx-auto px-5 3xl:px-0 max-w-7xl items-center gap-y-5">
          <CopyRightText link={"techvill.net"} />
          <ul className="space-x-4 xl:space-x-5 flex ">
            {socialLink.map((socialMedia) => (
              <SocialMediaIcon socialMedia={socialMedia} key={socialMedia.id} />
            ))}
          </ul>
        </div>
      </div>

      {/* FleetCart */}
      {!isLoading && <FleetCart martVillPrice={martVillPrice} />}

	  {/* FAB button - Scroll to top */}
	  <ScrollToTopFAB />
    </div>
  );
}

export default Footer;
