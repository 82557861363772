import { Fade } from "react-reveal";
import DocumentIcon from "../../assets/icon/documents.svg";
import KnowledgeIcon from "../../assets/icon/knowledge.svg";
import SupportIcon from "../../assets/icon/technical-support.svg";
import ChangelogIcon from "../../assets/icon/changelog.svg";

const features = [
	{
		icon: DocumentIcon,
		label: "Documentation",
		link: "https://docs.martvill.techvill.net/",
		description: "Read the documentation and get to known Martvill.",
	},
	{
		icon: KnowledgeIcon,
		label: "Knowledge Base",
		link: "https://help.techvill.net/category/martvill/",
		description: "Having some doubts? Check knowledgebase for Martvill",
	},
	{
		icon: SupportIcon,
		label: "Support",
		link: "https://support.techvill.org/",
		description: "Use our dedicated support area to get direct response from our agent.",
	},
	{
		icon: ChangelogIcon,
		label: "Changelog",
		link: "https://martvill.canny.io/changelog",
		description: "Keep track of updates with our Changelog",
	},
];

// For animation
let f = 0;

const HelpAndDocs = () => {
	return (
		<div className="min-h-[calc(100vh-150px)] bg-gray_4 pt-5 pb-16 sm:pb-24">
			<div className="container mx-auto max-w-7xl px-5 3xl:px-0">
				<h2 className="text-center uppercase text-[18px] md:text-[22px] 6xl:text-[26px] font-bold leading-[23px] lg:leading-[30px] 2xl:leading-[47px] mt-4 mb-3 md:mt-5 xl:mt-6 2xl:mb-[20px]">
					HELP &{" "}
					<span className="text-dark_yellow">DOCUMENTATION</span>
				</h2>
				<div className="3xl:px-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-[15px] lg:gap-x-8 gap-y-4 lg:gap-y-10">
					{features.map((feature) => {
						f++;
						const delay = Math.max(0, f * 50);
						return (
							<a
								className="h-full"
								key={feature.label}
								href={feature.link}
								target="_blank"
								rel="noreferrer"
							>
								<div className="h-full features-col group box-shadow_fe">
									<Fade
										bottom
										duration={700}
										delay={300 + delay}
									>
										<div className="flex flex-col items-center border border-[#FCCA19] bg-white  group-hover:border-transparent pt-8 lg:pt-8 6xl:pt-[42px] pb-[29px] 3xl:pb-7 6xl:pb-[38px] px-3.5 6xl:px-[24px] rounded-md h-full">
											<img
												className="w-12 sm:w-16 h-12 sm:h-16"
												src={feature.icon}
												alt={feature.label}
											/>
											<h2 className="group-hover:underline text-center text-xs lg:text-sm 3xl:text-lg font-bold mt-4 lg:mt-6 3xl:mt-[30px] mb-[9px] lg:mb-3 3xl:mb-4">
												{feature.label}
											</h2>
											<p className="text-center text-[#898989] roboto text-[11px] lg:text-sm 3xl:text-[15px] leading-4 lg:leading-5 3xl:leading-6 font-medium">
												{feature?.description}
											</p>
										</div>
									</Fade>
								</div>
							</a>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default HelpAndDocs;
