import { Helmet } from "react-helmet";
const thumbnail = "/thumbnail.png";

const MetaDocument = ({
	title,
	description,
	image,
	pageUrl = "https://martvill.techvill.net/",
	siteName = "Martvill",
}) => {
	return (
		<Helmet>
			{/* Basic Meta Tags*/}
			<title>{title}</title>
			<meta name="title" content={title} />
			<meta name="description" content={description} />

			{/*  Open Graph Meta Tags  */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={pageUrl} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image || thumbnail} />
			<meta property="og:site_name" content={siteName} />

			{/* Twitter Meta Tags */}
			<meta name="twitter:url" content={pageUrl} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:image" content={image} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:card" content={image} />
		</Helmet>
	);
};

export default MetaDocument;
