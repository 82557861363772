import React from "react";
import SearchIcon from "./SearchIcon";

const Input = ({ onChange }) => {
	return (
		<div className="max-w-xs w-full relative">
			<div className="absolute h-full flex items-center px-2.5 rounded-xl">
				<SearchIcon />
			</div>
			<input
				onChange={onChange}
				type="search"
				className="w-full p-1.5 pl-9 bg-white border border-gray rounded-sm text-sm font-normal focus:outline-none active:outline-none hover:border-gray-1 transition ease-out duration-200 placeholder:text-gray-1 text-light_black"
				placeholder="Search for plugins"
			/>
		</div>
	);
};

export default Input;
