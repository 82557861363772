import { useEffect, useState } from "react";
import MartvillLoader from "../../Components/MartvillLoader";

const Feedback = () => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		// Simulate external widget loading delay
		const delay = setTimeout(() => {
			setIsLoading(false);
		}, 2000);

		return () => clearTimeout(delay);
	}, []);

	useEffect(() => {
		(function (w, d, i, s) {
			function l() {
				if (!d.getElementById(i)) {
					var f = d.getElementsByTagName(s)[0],
						e = d.createElement(s);
					(e.type = "text/javascript"),
						(e.async = !0),
						(e.src = "https://canny.io/sdk.js"),
						f.parentNode.insertBefore(e, f);
				}
			}
			if ("function" != typeof w.Canny) {
				var c = function () {
					c.q.push(arguments);
				};
				(c.q = []),
					(w.Canny = c),
					"complete" === d.readyState
						? l()
						: w.attachEvent
						? w.attachEvent("onload", l)
						: w.addEventListener("load", l, !1);
			}
		})(window, document, "canny-jssdk", "script");

		Canny("render", {
			boardToken: process.env.REACT_APP_CANNY_BOARD_TOKEN,
			basePath: null, // See step 2
			ssoToken: null, // See step 3,
			theme: "light", // options: light [default], dark, auto
		});
	}, []);
	return (
		<div className="min-h-screen bg-gray_4 pt-5 pb-16">
			<div className="relative container mx-auto max-w-7xl px-5 3xl:px-0">
				{isLoading && (
					<div className="min-h-[calc(100vh-150px)] absolute top-0 left-0 w-full z-50 flex items-center justify-center">
						<MartvillLoader />
					</div>
				)}
				<div
					data-canny
					className={`${isLoading ? "opacity-40" : "opacity-100"}`}
				/>
			</div>
		</div>
	);
};

export default Feedback;
