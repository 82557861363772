import { useState, useEffect } from "react";

const useFetchAddons = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchAddons = async () => {
			try {
				const res = await fetch(
					"https://support.techvill.org/api/v1/martvill/addons"
				);
				const data = await res.json();
				setData(data?.response?.records);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
			}
		};
		fetchAddons();
	}, []);

	return { data, isLoading };
};

export default useFetchAddons;
