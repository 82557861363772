const FleetCart = ({martVillPrice}) => {
  return (
    <a
      className="z-50"
      href="https://codecanyon.net/item/martvill-a-global-multivendor-ecommerce-platform-to-sell-anything/43288879"
      target="_blank"
    >
      <div className="fleet-cart flex items-center justify-center fixed left-5 bottom-5 z-50 bg-[#82b440] w-[75px] h-[75px] rounded-full">
        <div className="flex">
          <span className="text-white text-base">$</span>
          <h2 className="text-white text-4xl font-normal">{martVillPrice}</h2>
        </div>
        <div className="flip-icon flex items-center justify-center">
          <div className="h-5 w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.869.088c-.572-.281-3.474.04-5.566 2.047-3.296 3.291-3.217 7.627-3.217 7.627s-.109.446-.573-.201c-1.016-1.295-.484-4.274-.424-4.689.084-.585-.289-.602-.444-.409-3.672 5.098-.356 9.272 1.815 10.597 2.542 1.551 7.556 1.55 9.553-2.85C16.501 6.731 13.586.439 12.869.088z"
                fill="#ffffff"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

export default FleetCart;
