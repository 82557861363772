import React from "react";
const PluginDetailsSkeleton = () => {
	return (
		<div className="py-10 sm:py-16 container mx-auto max-w-7xl px-5 3xl:px-0">
			<div className="h-10 max-w-sm rounded animate-pulse bg-[#c1c1c3]"></div>
			<div className="mt-2 flex gap-5">
				<div className="h-6 w-[80px] rounded animate-pulse bg-[#c1c1c3]"></div>
				<div className="h-6 w-[80px] rounded animate-pulse bg-[#c1c1c3]"></div>
			</div>
			<section className="mt-5 grid gap-10 grid-cols-1 xl:grid-cols-3">
				<div className="xl:col-span-2">
					<div className="bg-white p-4 rounded-md border border-[#e1e8ed]">
						<div className="relative main-item transition-all">
							<div className="w-full h-[150px] sm:h-[300px] md:h-[350px] animate-pulse bg-[#c1c1c3]"></div>
						</div>
						<div className="mt-5 h-7 w-full rounded animate-pulse bg-[#c1c1c3]"></div>
					</div>
				</div>
				<aside>
					{/* Select License */}
					<div className="shadow rounded-md overflow-hidden bg-[#f4f5f7]">
						{/* top section */}
						<div className="bg-yellow p-3.5 animate-pulse">
							<h5 className="roboto text-light_black font-bold text-xl break-all">
								Select License
							</h5>
							<p className="text-light_black break-all">
								Choose Suitable License
							</p>
						</div>
						<div className="flex gap-3 items-center justify-between p-3.5">
							<div className="h-32 w-full rounded animate-pulse bg-[#c1c1c3]"></div>
						</div>
						<div className="p-5">
							<div className="h-10 w-full rounded animate-pulse bg-[#c1c1c3]"></div>
						</div>
					</div>

					{/* Product Information */}
					<div className="shadow mt-6 rounded-md overflow-hidden bg-[#f4f5f7]">
						{/* top section */}
						<div className="bg-gray p-3.5">
							<h5 className="roboto text-white font-bold text-xl break-all animate-pulse">
								Product Information
							</h5>
						</div>
						<div className="h-32 w-full animate-pulse bg-[#c1c1c3]"></div>
					</div>
				</aside>
			</section>
		</div>
	);
};

export default PluginDetailsSkeleton;
