const SearchIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<g clipPath="url(#clip0_11091_5641)">
				<path
					d="M16.9221 15.9523L12.8944 11.7633C13.93 10.5322 14.4974 8.98328 14.4974 7.37077C14.4974 3.60329 11.4322 0.538086 7.66472 0.538086C3.89723 0.538086 0.832031 3.60329 0.832031 7.37077C0.832031 11.1383 3.89723 14.2035 7.66472 14.2035C9.07908 14.2035 10.4269 13.7769 11.5792 12.967L15.6376 17.1879C15.8072 17.364 16.0353 17.4612 16.2798 17.4612C16.5113 17.4612 16.7308 17.3729 16.8975 17.2125C17.2516 16.8718 17.2629 16.3067 16.9221 15.9523ZM7.66472 2.32053C10.4495 2.32053 12.715 4.58601 12.715 7.37077C12.715 10.1555 10.4495 12.421 7.66472 12.421C4.87995 12.421 2.61447 10.1555 2.61447 7.37077C2.61447 4.58601 4.87995 2.32053 7.66472 2.32053Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11091_5641">
					<rect
						width="16.9231"
						height="16.9231"
						fill="white"
						transform="translate(0.539062 0.538086)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SearchIcon;
