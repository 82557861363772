export function checkUpdateStatus(updateDateStr) {
	const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000; // One month in milliseconds
	const currentDate = new Date();
	const updateDate = new Date(updateDateStr);
	const differenceInMillis = currentDate - updateDate;

	if (differenceInMillis < oneMonthInMillis) {
		return "Recently updated";
	} else if (differenceInMillis < 2 * oneMonthInMillis) {
		return updateDateStr;
	} else {
		// Calculate the dynamic date for more than 2 months
		const twoMonthsAgo = new Date(currentDate);
		twoMonthsAgo.setMonth(currentDate.getMonth() - 2);

		const dynamicDate = `${twoMonthsAgo.getFullYear()}-${String(
			twoMonthsAgo.getMonth() + 1
		).padStart(2, "0")}-${String(twoMonthsAgo.getDate()).padStart(2, "0")}`;

		return dynamicDate;
	}
}
