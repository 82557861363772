import React from "react";

const SocialMediaIcon = ({ socialMedia }) => {
    const { url, color, socialMediaLogo } = socialMedia || {};
    return (
        <div>
            <a
                href={url}
                target='_blank'
                className={`w-[34px] h-[34px] flex items-center justify-center bg-transparent ${color}  transition-all rounded-[4px]`}
            >
                <img src={socialMediaLogo} alt='' />
            </a>
        </div>
    );
};

export default SocialMediaIcon;
