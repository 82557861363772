import React from "react";
import StarIcon from "./StarIcon";
import CartIcon from "./CartIcon";
import { Link } from "react-router-dom";
import DownloadIcon from "./DownloadIcon";

const PluginCard = ({ addon }) => {
	const demoSiteUrl = addon?.attributes?.find(
		(attr) => attr?.name === "demo-url"
	);

	const addonName = addon?.name
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^a-zA-Z0-9-]/g, "");

	return (
		<div className="flex flex-col bg-white rounded-md overflow-hidden h-full border border-[#e1e8ed]/80">
			{/* card image */}
			<div className="h-[200px]">
				<Link to={`/plugins/${addon?.id}/${addonName}`}>
					<img
						src={addon?.previews?.landscape_preview?.landscape_url}
						alt="landscape-preview"
						className="w-full h-full object-cover"
					/>
				</Link>
			</div>
			{/* card body */}
			<div className="px-5 pt-4">
				<h6 className="roboto truncate font-semibold text-light_black transition-all hover:underline">
					<Link to={`/plugins/${addon?.id}/${addonName}`}>{addon?.name}</Link>
				</h6>
				<p className="text-sm text-gray">
					<b>Category:</b> {addon?.classification}
				</p>
				<h5 className="mt-3 mb-1 text-xl font-bold text-light_black">
					{addon?.price_cents == 0 ? "Free" : "$" + addon?.price_cents / 100}
				</h5>
			</div>
			{/*card footer */}
			<div className="mt-auto px-5 pb-4 flex gap-2 justify-between items-center">
				<div>
					{addon?.rating_count > 0 && (
						<div className="flex gap-1">
							<div className="flex">
								{[...Array(parseInt(addon?.rating))].map((e, i) => (
									<StarIcon key={i} />
								))}
							</div>
							<p className="text-xs text-gray">({addon?.rating_count})</p>
						</div>
					)}

					<p className="text-sm text-gray">
						{addon?.number_of_sales}{" "}
						{addon?.price_cents == 0 ? "Downloads" : "Sales"}
					</p>
				</div>
				{/* card actions */}
				<div className="flex gap-2">
					{addon?.price_cents == 0 ? (
						<a
							className="p-1 sm:p-1.5 flex items-center justify-center border border-gray w-12 rounded-sm"
							href={`https://support.techvill.org/api/v1/addon/download/${addon?.id}`}
							rel="noopener noreferrer"
						>
							<DownloadIcon />
						</a>
					) : (
						<a
							className="p-1 sm:p-1.5 flex items-center justify-center border border-gray w-12 rounded-sm"
							href={`https://codecanyon.net/cart/configure_before_adding/${addon?.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<CartIcon />
						</a>
					)}

					{demoSiteUrl?.value && (
						<a
							className="w-full flex items-center justify-center p-1 sm:p-1.5 text-sm text-center font-semibold text-light_black border border-gray rounded-sm hover:bg-light_black hover:text-white transition-all"
							href={demoSiteUrl?.value}
							target="_blank"
							rel="noopener noreferrer"
						>
							Live Preview
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default PluginCard;
